<template>
  <div class="admin appointments">

    <header class="admin-header cf">
      <h1>Termine</h1>
      <div class="meta">
        <a @click="open_detailed_modal" class="button button-red">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="calendar-plus" class="svg-inline--fa fa-calendar-plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path fill="currentColor" d="M96 32C96 14.33 110.3 0 128 0C145.7 0 160 14.33 160 32V64H288V32C288 14.33 302.3 0 320 0C337.7 0 352 14.33 352 32V64H400C426.5 64 448 85.49 448 112V160H0V112C0 85.49 21.49 64 48 64H96V32zM448 464C448 490.5 426.5 512 400 512H48C21.49 512 0 490.5 0 464V192H448V464zM200 272V328H144C130.7 328 120 338.7 120 352C120 365.3 130.7 376 144 376H200V432C200 445.3 210.7 456 224 456C237.3 456 248 445.3 248 432V376H304C317.3 376 328 365.3 328 352C328 338.7 317.3 328 304 328H248V272C248 258.7 237.3 248 224 248C210.7 248 200 258.7 200 272z"></path>
          </svg>
          <span>Termin reservieren</span>
        </a>
        <a @click="open_modal" class="button button-red" style="margin-left:20px">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" class="svg-inline--fa fa-plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path fill="currentColor" d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"></path>
          </svg>
          <span>Soforttermin erstellen</span>
        </a>
      </div>
    </header>

    <div v-if="loading" class="loading-wrap">
      <span class="loading-spinner"></span>
    </div>
    <div v-else>

      <div class="row row-gutter-20">
        <div class="col-12">
          <div class="search-wrap" style="margin-bottom:10px">
            <input v-model="search_phrase" v-on:input="search_handler" type="text" class="form-search" style="width:81%">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
          </div>
        </div>
        <div class="col-12">
          <div style="text-align:right;padding:8px 0 0 0">
            <ul class="nav nav-tabs">
              <li :class="check_filter_class('all')"><a @click="select_filter_class('all')" style="margin-left:10px">Alle</a></li>
              <li :class="check_filter_class('open')"><a @click="select_filter_class('open')" style="margin-left:10px">Nur Offene</a></li>
              <li :class="check_filter_class('unpaid')"><a @click="select_filter_class('unpaid')" style="margin-left:10px">Nur unbezahlt</a></li>
            </ul>
          </div>
        </div>
      </div>

      <div class="filterbar cf">
        <ul class="nav nav-tabs">
          <li :class="check_date_class('today')"><a @click="select_date_class('today')">Heute</a></li>
          <li :class="check_date_class('tomorrow')"><a @click="select_date_class('tomorrow')">Morgen</a></li>
          <li :class="check_date_class('all')"><a @click="select_date_class('all')">Gesamt</a></li>
          <li :class="check_date_class('cal')">
            <a @click="select_date_class('cal')">
              <svg style="display:block;width:18px" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
              </svg>
            </a>
            <div v-if="show_cal" class="cal-overlay">
              <v-date-picker v-model="app_range" :first-day-of-week="2" locale="de" color="red" is-range>
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="row row-gutter-10">
                    <div class="col-12">
                      <div class="form-wrap">
                        <label>Zeitraum von</label>
                        <input
                          :value="inputValue.start"
                          v-on="inputEvents.start"
                          class="form-input"
                        />
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-wrap">
                        <label>Zeitraum bis</label>
                        <input
                          :value="inputValue.end"
                          v-on="inputEvents.end"
                          class="form-input"
                        />
                      </div>
                    </div>
                  </div>
                </template>
              </v-date-picker>

            </div>
          </li>
        </ul>
        <ul class="nav nav-tabs">
          <li v-if="computed_locations.length > 1" key="" :class="check_location_class('')">
            <a @click="select_location_class('')">Alle Standorte</a>
          </li>
          <li v-for="location in computed_locations" v-bind:key="location.id" :class="check_location_class(location.id)">
            <a @click="select_location_class(location)">{{ location.name }}</a>
          </li>
        </ul>
      </div>

      <div v-if="search_loading" class="loading-wrap">
        <span class="loading-spinner"></span>
      </div>
      <div v-else>

        <div v-if="selected_location == 'Ausländeramt'">
          <div class="row row-gutter-20">
            <div class="col-12">
              <appointments-simple :appointments="regular_appointments" :user="user" @get_appointments="get_appointments"></appointments-simple>
            </div>
            <div class="col-12">
              <appointments-simple :appointments="side_appointments" :user="user" @get_appointments="get_appointments"></appointments-simple>
            </div>
          </div>
        </div>
        <div v-else>
          <appointments :appointments="appointments" :user="user" @get_appointments="get_appointments"></appointments>
        </div>

      </div>

    </div>

    <vue-final-modal classes="modal-wrap" content-class="modal-inner" v-model="modal_add">
      <svg @click="modal_add = false" aria-hidden="true" focusable="false" data-prefix="far" data-icon="times-circle" class="modal-close svg-inline--fa fa-times-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"></path>
      </svg>
      <div class="modal-head">
        <h3>Soforttermin hinzufügen</h3>
      </div>
      <div class="modal-body">

        <div class="row row-gutter-20">
          <div class="col-20">
            <div class="row row-gutter-20">
              <div class="col-8">
                <div class="form-wrap select-wrap">
                  <label for="category_id">Standort</label>
                  <select v-model="appointment.location_id" class="form-input">
                    <option v-for="location in computed_locations" v-bind:key="location.id" v-bind:value="location.id">{{ location.name }}</option>
                  </select>
                </div>
              </div>
              <div class="col-8">
                <div class="form-wrap select-wrap">
                  <label for="category_id">Kategorie</label>
                  <div v-if="!appointment.location_id">
                    <span style="display:block;font-size:11px;padding-top:12px;">Bitte Standort auswählen</span>
                  </div>
                  <div v-else-if="loading_categories" class="loading-wrap" style="padding: 0">
                    <span class="loading-spinner" style="height:18px;width:18px;"></span>
                  </div>
                  <select v-else v-model="appointment.category_id" class="form-input">
                    <option v-for="category in categories" v-bind:key="category.id" v-bind:value="category.id">{{ category.name }}</option>
                  </select>
                </div>
              </div>
              <div class="col-8">
                <div class="form-wrap select-wrap">
                  <label for="category_id">Leistung</label>
                  <div v-if="!appointment.category_id">
                    <span style="display:block;font-size:11px;padding-top:12px;">Bitte Kategorie auswählen</span>
                  </div>
                  <div v-else-if="loading_services" class="loading-wrap" style="padding: 0">
                    <span class="loading-spinner" style="height:18px;width:18px;"></span>
                  </div>
                  <select v-else v-model="appointment.service_id" class="form-input">
                    <option v-for="service in services" v-bind:key="service.id" v-bind:value="service.id">{{ service.name }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="form-wrap">
              <label for="service_amount">Anzahl</label>
              <input v-model="appointment.service_amount" class="form-input input-grey" type="text" name="service_amount" id="service_amount">
            </div>
          </div>
        </div>

        <div class="row row-gutter-20">
          <div class="col-12">
            <div class="form-wrap">
              <label for="first_name" class="form-label">Vorname</label>
              <input v-model="appointment.first_name" class="form-input input-grey" type="text" name="first_name" id="first_name">
            </div>
          </div>
          <div class="col-12">
            <div class="form-wrap">
              <label for="last_name" class="form-label">Nachname</label>
              <input v-model="appointment.last_name" class="form-input input-grey" type="text" name="last_name" id="last_name">
            </div>
          </div>
        </div>

      </div>
      <div class="modal-meta">
        <span v-if="loading_create" class="button button-light-gray button-100">Lädt... Bitte warten.</span>
        <a v-else @click="create_appointment" class="button button-red button-100">Soforttermin erstellen ›</a>
      </div>
    </vue-final-modal>

    <vue-final-modal classes="modal-wrap" content-class="modal-inner" v-model="modal_detailed_add">
      <div class="modal-head">
        <h3>Termin hinzufügen</h3>
        <div style="position:absolute;right:60px;top:17px;">
          <ul v-if="modal_detailed_loading == false" class="nav nav-tabs">
            <li v-if="appointment_detailed_mode == 'default'" class="active" style="margin-right:5px">
              <a>Standard Modus</a>
            </li>
            <li v-else style="margin-right:5px">
              <a @click="enable_mode('default')">Standard Modus</a>
            </li>
            <li v-if="appointment_detailed_mode == 'free'" class="active" style="margin-left:5px">
              <a>Freier Modus</a>
            </li>
            <li v-else style="margin-left:5px">
              <a @click="enable_mode('free')">Freier Modus</a>
            </li>
          </ul>
        </div>
        <a @click="modal_detailed_add = false" style="position:absolute;right:20px;top:22px;">
          <svg style="width:18px" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </a>
      </div>
      <div class="modal-body">

        <div v-if="appointment_detailed_mode">

          <div v-for="create_service in create_services" v-bind:key="create_service.id">
            <div class="row row-gutter-10">
              <div class="col-6">
                <div class="form-wrap select-wrap">
                  <label for="category_id">Standort</label>
                  <select v-model="create_service.location_id" @change="get_categories(create_service)" class="form-input" style="padding:8px 12px">
                    <option v-for="location in computed_locations" v-bind:key="location.id" v-bind:value="location.id">{{ location.name }}</option>
                  </select>
                </div>
              </div>
              <div class="col-6">
                <div class="form-wrap select-wrap">
                  <label for="category_id">Kategorie</label>
                  <div v-if="!create_service.location_id" class="form-input" style="cursor:not-allowed;height:35px;background:#f1f1f1;"></div>
                  <div v-else-if="create_service.loading_categories" class="loading-wrap" style="padding: 0">
                    <span class="loading-spinner" style="height:18px;width:18px;"></span>
                  </div>
                  <select v-else v-model="create_service.category_id" @change="get_services(create_service)" class="form-input" style="padding:8px 12px">
                    <option v-for="category in create_service.categories" v-bind:key="category.id" v-bind:value="category.id">{{ category.name }}</option>
                  </select>
                </div>
              </div>
              <div class="col-6">
                <div class="form-wrap select-wrap">
                  <label for="category_id">Leistung</label>
                  <div v-if="!create_service.category_id" class="form-input" style="cursor:not-allowed;height:35px;background:#f1f1f1;"></div>
                  <div v-else-if="create_service.loading_services" class="loading-wrap" style="padding: 0">
                    <span class="loading-spinner" style="height:18px;width:18px;"></span>
                  </div>
                  <select v-else v-model="create_service.service_id" class="form-input" style="padding:8px 12px">
                    <option v-for="service in create_service.services" v-bind:key="service.id" v-bind:value="service.id">{{ service.name }}</option>
                  </select>
                </div>
              </div>
              <div class="col-4">
                <div class="form-wrap">
                  <label for="service_amount">Anzahl</label>
                  <input v-model="create_service.service_amount" class="form-input input-grey" type="text" name="service_amount" style="padding:6px 12px">
                </div>
              </div>
              <div class="col-2">
                <a @click="delete_create_service(create_service)">
                  <svg style="margin-top:28px;width:18px" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                  </svg>
                </a>
              </div>
            </div>
          </div>

          <div class="cf">
            <a @click="add_create_service" class="button button-light-gray button-small" style="float:left;padding: 0.5rem 1rem">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
              </svg>
              <span>Weitere Leistung</span>
            </a>
            <a @click="find_detailed_slot" class="button button-red button-small" style="float:right;padding: 0.5rem 1rem">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
              </svg>
              <span>Termin finden</span>
            </a>
          </div>

          <div v-if="detailed_appointment.find_slot">
            <div v-if="modal_detailed_loading" class="loading-wrap">
              <span class="loading-spinner"></span>
            </div>
            <div v-else style="padding-top:20px">

              <div class="row row-gutter-20">
                <div class="col-12">
                  <div class="form-wrap">
                    <label for="date">Datum <span class="rq">(* Pflichtfeld)</span></label>
                    <v-date-picker v-model="cal_date" :min-date='cal_min_date' :max-date='cal_max_date' :disabled-dates="cal_disabled_dates" :first-day-of-week="2" locale="de" color="red" mode="date">
                      <template v-slot="{ inputValue, inputEvents }">
                        <input
                          class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300 form-input"
                          :value="inputValue"
                          v-on="inputEvents"
                        />
                      </template>
                    </v-date-picker>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-wrap select-wrap">
                    <label for="time">Uhrzeit <span class="rq">(* Pflichtfeld)</span></label>
                    <div v-if="!detailed_appointment.date" class="form-input" style="cursor:not-allowed;height:43px;background:#f1f1f1;"></div>
                    <div v-else-if="detailed_appointment.loading_slots" class="loading-wrap" style="padding: 0">
                      <span class="loading-spinner" style="height:18px;width:18px;"></span>
                    </div>
                    <select v-else v-model="detailed_appointment.time" class="form-input" style="height: 43px">
                      <option v-for="slot in slots.slots" v-bind:key="slot" v-bind:value="slot">{{ slot }}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="row row-gutter-20">
                <div class="col-12">
                  <div class="form-wrap">
                    <label for="first_name" class="form-label">Vorname <span class="rq">(* Pflichtfeld)</span></label>
                    <input v-model="detailed_appointment.first_name" class="form-input input-grey" type="text" name="first_name" id="first_name">
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-wrap">
                    <label for="last_name" class="form-label">Nachname <span class="rq">(* Pflichtfeld)</span></label>
                    <input v-model="detailed_appointment.last_name" class="form-input input-grey" type="text" name="last_name" id="last_name">
                  </div>
                </div>
              </div>
              <div class="row row-gutter-20">
                <div class="col-12">
                  <div class="form-wrap">
                    <label for="email">E-Mail Adresse</label>
                    <input v-model="detailed_appointment.email" type="text" class="form-input" id="email">
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-wrap">
                    <label for="phone">Telefon</label>
                    <input v-model="detailed_appointment.phone" type="text" class="form-input" id="phone">
                  </div>
                </div>
              </div>

              <div class="form-wrap">
                <label for="description">Notiz zum Termin</label>
                <textarea v-model="detailed_appointment.description" id="description" name="description" rows="1" class="form-input"></textarea>
              </div>

            </div>
          </div>
        </div>

      </div>
      <div v-if="detailed_appointment.find_slot && modal_detailed_loading == false" class="modal-meta">
        <span v-if="loading_create" class="button button-light-gray button-100">Lädt... Bitte warten.</span>
        <a v-else @click="create_detailed_appointment" class="button button-red button-100">Termin erstellen ›</a>
      </div>
    </vue-final-modal>

  </div>
</template>

<script>
import Appointments from '@/components/appointments'
import AppointmentsSimple from '@/components/appointments_simple'

export default {
  name: 'admin_appointments',
  components: {
    Appointments,
    AppointmentsSimple
  },
  data () {
    return {
      loading: true,
      loading_create: false,
      loading_categories: false,
      loading_services: false,
      modal_detailed_loading: false,
      loading_detailed_categories: false,
      loading_detailed_services: false,
      user_id: null,
      user: {},
      user_location: null,
      class_date: "today",
      class_location: "",
      class_filter: "all",
      modal_add: false,
      modal_detailed_add: false,
      appointments: [],
      regular_appointments: [],
      side_appointments: [],
      locations: [],
      categories: [],
      services: [],
      slots: [],
      appointment: {
        appointment_type: "spontaneous",
        status: "checked_in",
        service_amount: 1
      },
      detailed_appointment: {
        appointment_type: "planned",
        status: "created",
        find_slot: false,
        loading_slots: false
      },
      create_services: [
        {
          id: 1,
          location_id: null,
          categories: [],
          category_id: null,
          services: [],
          service_id: null,
          service_amount: 1
        }
      ],
      cal_date: null,
      cal_disabled_dates: [],
      cal_min_date: null,
      cal_max_date: null,
      search_loading: false,
      search_phrase: "",
      searching: false,
      app_range: {
        start: null,
        end: null
      },
      show_cal: false,
      appointment_detailed_mode: "default",
      selected_location: null
    }
  },
  computed: {
    computed_locations: function () {
      var locations = [];
      this.locations.forEach(location => {
        if (this.user_location) {
          if (this.user_location == location.id) {
            locations.push(location);
          }
        } else {
          locations.push(location);
        }
      })
      return locations;
    },
  },
  methods: {
    async get_data() {
      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/locations', { headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.locations = response.data.locations;
      })
      await this.$http.get(process.env.VUE_APP_BASE_API+'/user', { headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.user_id = response.data.user.id;
      })
      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/users/'+this.user_id, { headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.user = response.data.user;
        if (this.user.locations && this.user.locations.length > 0) {
          this.user_location = this.user.locations[0].id;
        }
      })
      await this.$http.get(process.env.VUE_APP_BASE_API+'/service_dates', { headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        response.data.forEach(date => {
          if (date.weekdays) {
            this.cal_disabled_dates.push(date)
          } else {
            this.cal_disabled_dates.push({
              start: new Date(date.start),
              end: new Date(date.end)
            })
          }
        });
      });
      this.cal_min_date = new Date();
      var d = new Date();
      d.setMonth(d.getMonth() + 3);
      this.cal_max_date = d;
      this.get_appointments();
    },
    get_appointments() {
      this.search_loading = true;
      if (this.user_location) {
        this.class_location = this.user_location;
      }
      this.$http.get(process.env.VUE_APP_BASE_API+'/admin/appointments', {
        params: {
          filter_date: this.class_date,
          location_id: this.class_location,
          filter_val: this.class_filter,
          search_phrase: this.search_phrase,
          range_from: this.app_range.start,
          range_to: this.app_range.end,
        },
        headers: { Authorization: this.$store.getters.get_token }
      })
      .then(response => {
        this.regular_appointments = [];
        this.side_appointments = [];
        if (this.selected_location && this.selected_location == "Ausländeramt") {
          response.data.appointments.forEach(appointment => {
            if (appointment.services.length > 0 && appointment.services[0].service_name == "Aufenthaltstitel abholen") {
              this.side_appointments.push(appointment);
            } else {
              this.regular_appointments.push(appointment);
            }
          });
        }
        this.appointments = response.data.appointments;
        this.loading = false;
        this.search_loading = false;
        this.searching = false;
      })
    },
    async create_appointment() {
      this.loading_create = true;
      var services = []
      var service_obj = {
        service_id: this.appointment.service_id
      }
      if (this.appointment.service_amount) {
        service_obj.service_amount = this.appointment.service_amount;
      }
      services.push(service_obj);
      this.appointment.services_attributes = services;
      await this.$http.post(process.env.VUE_APP_BASE_API+'/admin/appointments', this.appointment, { headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.get_data();
        this.get_appointments();
        this.modal_add = false;
        this.detailed_appointment = {
          appointment_type: "planned",
          status: "created",
          service_amount: 1,
          loading_slots: false
        };
        this.appointment = {
          appointment_type: "spontaneous",
          status: "checked_in",
          service_amount: 1
        };
        if (this.user_location) {
          this.appointment.location_id = null;
          this.appointment.location_id = this.user_location;
          this.detailed_appointment.location_id = null;
          this.detailed_appointment.location_id = this.user_location;
        }
        this.$notify({
          title: "Erfolgreich erstellt.",
          type: "success"
        });
        this.$router.push('/admin/appointments/'+response.data.appointment.id);
      })
      .catch(error => {
        error.response.data.errors.forEach(value => {
          this.$notify({
            title: value,
            type: "error"
          });
        });
      });
      this.loading_create = false;
    },
    async create_detailed_appointment() {
      this.loading_create = true;
      var services = []
      /*var service_obj = {
        service_id: this.detailed_appointment.service_id
      }
      if (this.detailed_appointment.service_amount) {
        service_obj.service_amount = this.detailed_appointment.service_amount;
      }
      services.push(service_obj);*/
      for (let i = 0; i < this.create_services.length; i += 1) {
        console.log(this.create_services[i]);
        services.push({
          service_id: this.create_services[i].service_id,
          service_amount: this.create_services[i].service_amount
        })
      }
      this.detailed_appointment.services_attributes = services;
      this.detailed_appointment.created_by = this.user_id;
      await this.$http.post(process.env.VUE_APP_BASE_API+'/admin/appointments', this.detailed_appointment, { headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.get_data();
        this.get_appointments();
        this.modal_detailed_add = false;
        this.detailed_appointment = {
          appointment_type: "planned",
          status: "created",
          service_amount: 1,
          loading_slots: false
        };
        this.appointment = {
          appointment_type: "spontaneous",
          status: "checked_in",
          service_amount: 1
        };
        if (this.user_location) {
          this.appointment.location_id = null;
          this.appointment.location_id = this.user_location;
          this.detailed_appointment.location_id = null;
          this.detailed_appointment.location_id = this.user_location;
        }
        this.$notify({
          title: "Erfolgreich erstellt.",
          type: "success"
        });
        this.$router.push('/admin/appointments/'+response.data.appointment.id);
      })
      .catch(error => {
        error.response.data.errors.forEach(value => {
          this.$notify({
            title: value,
            type: "error"
          });
        });
      });
      this.loading_create = false;
    },
    async get_slots(date) {
      this.detailed_appointment.loading_slots = true;
      this.slots = [];
      await this.$http.post(process.env.VUE_APP_BASE_API+'/admin/slots', {
        date: date,
        selected_services: this.create_services,
        appointment_detailed_mode: this.appointment_detailed_mode
      }, { headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.slots = response.data;
      });
      this.detailed_appointment.loading_slots = false;
    },
    search_handler() {
      if (this.searching == false) {
        this.search_loading = true;
        this.searching = true;
        this.get_appointments();
      }
    },
    check_date_class(date) {
      if (date == this.class_date) {
        if (date == "cal") {
          return 'active cal-sel';
        } else {
          return 'active';
        }
      } else {
        if (date == "cal") {
          return 'cal-sel';
        } else {
          return '';
        }
      }
    },
    select_date_class(date) {
      this.show_cal = !this.show_cal;
      this.class_date = date;
      if (date != "cal") {
        this.show_cal = false;
        this.app_range = {
          start: null,
          end: null
        }
        this.get_appointments();
      }
    },
    check_filter_class(date) {
      if (date == this.class_filter) {
        return 'active';
      } else {
        return '';
      }
    },
    select_filter_class(filter) {
      this.class_filter = filter;
      this.get_appointments();
    },
    check_location_class(location) {
      if (location == this.class_location) {
        return 'active';
      } else {
        return '';
      }
    },
    select_location_class(location) {
      if (location && location.id) {
        this.class_location = location.id;
        this.selected_location = location.name;
      } else {
        this.class_location = '';
        this.selected_location = null;
      }
      this.get_appointments();
    },
    open_detailed_modal() {
      this.detailed_appointment = {
        appointment_type: "planned",
        status: "created",
        loading_slots: false
      };
      if (this.user_location) {
        this.detailed_appointment.location_id = this.user_location;
      }
      this.modal_detailed_add = true;
    },
    open_modal() {
      this.appointment = {
        appointment_type: "spontaneous",
        status: "checked_in"
      };
      if (this.user_location) {
        this.appointment.location_id = this.user_location;
      }
      this.modal_add = true;
    },
    async get_busy_dates() {
      this.modal_detailed_loading = true;
      this.cal_disabled_dates = [];
      await this.$http.get(process.env.VUE_APP_BASE_API+'/service_dates', { headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        response.data.forEach(date => {
          if (date.weekdays) {
            this.cal_disabled_dates.push(date)
          } else {
            this.cal_disabled_dates.push({
              start: new Date(date.start),
              end: new Date(date.end)
            })
          }
        });
      });
      await this.$http.post(process.env.VUE_APP_BASE_API+'/admin/busy_dates', {
        selected_services: this.create_services
      }, {
        headers: {
          Authorization: this.$store.getters.get_token
        }
      })
      .then(response => {
        response.data.forEach(date => {
          this.cal_disabled_dates.push({
            start: new Date(date.start),
            end: new Date(date.end)
          })
        });
      })
      .catch(error => {
        this.reset_datetime();
        if (error.response.data && error.response.data.errors) {
          error.response.data.errors.forEach(val => {
            this.$notify({
              title: val,
              type: "error"
            });
          });
        } else {
          this.$notify({
            title: "Bitte alle Felder ausfüllen.",
            type: "error"
          });
        }
      });
      this.modal_detailed_loading = false;
    },
    async load_categories() {
      this.loading_categories = true;
      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/categories', { params: { location_id: this.appointment.location_id }, headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.categories = response.data.categories;
      })
      this.loading_categories = false;
    },
    async load_services() {
      this.loading_services = true;
      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/services', { params: { category_id: this.appointment.category_id }, headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.services = response.data.services;
      })
      this.loading_services = false;
    },
    async load_detailed_categories() {
      this.loading_detailed_categories = true;
      this.detailed_appointment.category_id = null;
      this.detailed_appointment.service_id = null;
      this.detailed_appointment.date = null;
      this.detailed_appointment.time = null;
      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/categories', { params: { location_id: this.detailed_appointment.location_id }, headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.categories = response.data.categories;
      })
      this.loading_detailed_categories = false;
    },
    async load_detailed_services() {
      this.loading_detailed_services = true;
      this.detailed_appointment.service_id = null;
      this.detailed_appointment.date = null;
      this.detailed_appointment.time = null;
      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/services', { params: { category_id: this.detailed_appointment.category_id }, headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.services = response.data.services;
      })
      this.loading_detailed_services = false;
    },
    add_create_service() {
      this.reset_datetime();
      this.create_services.push({
        id: this.create_services.length+1,
        location_id: null,
        categories: [],
        category_id: null,
        services: [],
        service_id: null,
        service_amount: 1
      })
    },
    delete_create_service(create_service) {
      this.create_services.splice(this.create_services.indexOf(create_service), 1);
    },
    async get_categories(create_service) {
      this.reset_datetime();
      create_service.loading_categories = true;
      this.detailed_appointment.location_id = create_service.location_id;
      var cind = this.create_services.indexOf(create_service);
      var create_service_obj = this.create_services[cind];
      create_service_obj.category_id = null;
      create_service_obj.service_id = null;
      create_service_obj.date = null;
      create_service_obj.time = null;
      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/categories', { params: { location_id: create_service.location_id }, headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        create_service_obj.categories = response.data.categories;
      })
      this.create_services.splice(cind, 1, create_service_obj);
      create_service.loading_categories = false;
    },
    async get_services(create_service) {
      this.reset_datetime();
      create_service.loading_services = true;
      this.detailed_appointment.category_id = create_service.category_id;
      var cind = this.create_services.indexOf(create_service);
      var create_service_obj = this.create_services[cind];
      create_service_obj.service_id = null;
      create_service_obj.date = null;
      create_service_obj.time = null;
      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/services', { params: { category_id: create_service.category_id }, headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        create_service_obj.services = response.data.services;
      })
      this.create_services.splice(cind, 1, create_service_obj);
      create_service.loading_services = false;
    },
    async change_service_amount() {
      this.reset_datetime();
    },
    reset_datetime() {
      this.detailed_appointment.date = null;
      this.detailed_appointment.time = null;
      this.detailed_appointment.find_slot = false;
      this.modal_detailed_loading = false;
    },
    find_detailed_slot() {
      // Check if create_services is properly filled out
      this.detailed_appointment.find_slot = true;
      this.modal_detailed_loading = true;
      if (this.appointment_detailed_mode == "default") {
        this.cal_date = null;
        this.detailed_appointment.date = null;
        this.detailed_appointment.time = null;
        this.get_busy_dates();
      } else {
        this.modal_detailed_loading = false;
      }
    },
    enable_mode(mode) {
      this.detailed_appointment = {
        appointment_type: "planned",
        status: "created",
        find_slot: false,
        loading_slots: false
      }
      this.create_services = [
        {
          id: 1,
          location_id: null,
          categories: [],
          category_id: null,
          services: [],
          service_id: null,
          service_amount: 1
        }
      ]
      this.cal_date = null;
      this.appointment_detailed_mode = mode;
      if (this.appointment_detailed_mode == "free") {
        this.cal_disabled_dates = [];
      }
    }
  },
  mounted () {
    this.get_data();
  },
  watch: {
    'appointment.location_id': function() {
      this.load_categories();
    },
    'appointment.category_id': function() {
      this.load_services();
    },
    'detailed_appointment.location_id': function() {
      this.load_detailed_categories();
    },
    'detailed_appointment.category_id': function() {
      this.load_detailed_services();
    },
    'detailed_appointment.service_id': function() {
      this.cal_date = null;
      if (this.detailed_appointment.service_id && this.appointment_detailed_mode == 'default') {
        this.get_busy_dates();
      } else {
        this.modal_detailed_loading = false;
      }
    },
    cal_date() {
      this.detailed_appointment.date = this.cal_date;
      this.detailed_appointment.time = null;
      this.get_slots(this.cal_date);
    },
    app_range() {
      this.search_loading = true;
      this.show_cal = false;
      if (this.class_date == "cal") {
        this.class_date = "";
        this.class_location = "";
        this.class_filter = "all";
      }
      this.get_appointments();
      /*
      this.$http.get(process.env.VUE_APP_BASE_API+'/admin/appointments', {
        params: {
          range_from: this.app_range.start,
          range_to: this.app_range.end,
          location_id: this.class_location,
        },
        headers: { Authorization: this.$store.getters.get_token }
      })
      .then(response => {
        this.appointments = response.data.appointments;
        this.search_loading = false;
      })*/
    },
    'appointment_detailed_mode': function() {
      this.cal_date = null;
      if (this.appointment_detailed_mode == 'free') {
        console.log("FREE")
      }
    }
  }
}
</script>
